import { createContext, useContext, useState } from "react";
import axios from "axios";
import { useAuthContext } from "./AuthContext";
import { useFetchContext } from "./FetchContext";
import { toast } from "react-toastify";
import { useAdminContext } from "./AdminContext";
import { baseUrl } from "../data/constant";

const INITIAL_STATES = {
  isLoading: null,
  response: null,
  errorResponse: null,
  isError: null,
  errorMessage: "",
};

export const PostContext = createContext(INITIAL_STATES);

export const PostContextProvider = ({ children }) => {
  const [postState, setPostState] = useState(INITIAL_STATES);
  const { token, isAdmin, loadUser } = useAuthContext();
  const { fetchPackages, fetchClasses, fetchCategories } = useFetchContext();

  const PostPackage = async (formData) => {
    setPostState({ ...postState, isLoading: true });
    try {
      const { data } = await axios.post(`${baseUrl}/package/create`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        console.log("🚀 ~ PostPackage ~ data:", data);
        setPostState({
          ...postState,
          response: data.message,
        });
        toast.success("Package Added");
        fetchPackages();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Something Went Wrong");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const DeletePackage = async (id) => {
    setPostState({ ...postState, isLoading: true });
    try {
      const { data } = await axios.delete(`${baseUrl}/package/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setPostState({
          ...postState,
          response: data.message,
        });
        toast.success("Package Deleted");
        fetchPackages();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Something Went Wrong");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const EditPackage = async (id, formData) => {
    setPostState({ ...postState, isLoading: true });
    try {
      const { data } = await axios.put(`${baseUrl}/package/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setPostState({
          ...postState,
          response: data.message,
        });
        toast.success("Package Updated");
        fetchPackages();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Something Went Wrong");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const PostClass = async (formData) => {
    setPostState({ ...postState, isLoading: true });
    try {
      const { data } = await axios.post(`${baseUrl}/product`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setPostState({
          ...postState,
          response: data.message,
        });
        toast.success("Product Added");
        fetchClasses();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Something Went Wrong");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const EditClassData = async (id, formData) => {
    setPostState({ ...postState, isLoading: true });
    try {
      const { data } = await axios.put(`${baseUrl}/product/${id}`, formData, {
        headers: {
          "x-allow-no-images": true,
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setPostState({
          ...postState,
          response: data.message,
        });
        toast.success("Product Updated");
        fetchClasses();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Something Went Wrong");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const DeleteClass = async (id) => {
    setPostState({ ...postState, isLoading: true });
    try {
      const { data } = await axios.delete(`${baseUrl}/product/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        console.log("🚀 ~ DeleteClass ~ data:", data);
        setPostState({
          ...postState,
          response: data.message,
        });
        toast.success("Product Deleted");
        fetchClasses();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Something Went Wrong");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const DeleteProductImage = async (id, public_id) => {
    setPostState({ ...postState, isLoading: true });
    try {
      const { data } = await axios.delete(`${baseUrl}/product/image/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          public_id,
        },
      });
      if (data.success) {
        console.log("🚀 ~ DeleteProductImage ~ data:", data);
        setPostState({
          ...postState,
          response: data.message,
        });
        toast.success("Image Deleted");
        fetchClasses();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Something Went Wrong");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const PostCategory = async (formData) => {
    setPostState({ ...postState, isLoading: true });
    try {
      const { data } = await axios.post(
        `${baseUrl}/product-category`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        console.log("🚀 ~ PostCategory ~ data:", data);
        setPostState({
          ...postState,
          response: data.message,
        });
        toast.success("Category Created");
        fetchCategories();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Something Went Wrong");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const UpdateCategory = async (formData, id) => {
    setPostState({ ...postState, isLoading: true });
    try {
      const { data } = await axios.put(
        `${baseUrl}/product-category/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        console.log("🚀 ~ UpdateCategory ~ data:", data);
        setPostState({
          ...postState,
          response: data.message,
        });
        toast.success("Category Updated");
        fetchCategories();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Something Went Wrong");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const DeleteCategory = async (id) => {
    setPostState({ ...postState, isLoading: true });
    try {
      const { data } = await axios.delete(`${baseUrl}/product-category/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        console.log("🚀 ~ DeleteCategory ~ data:", data);
        setPostState({
          ...postState,
          response: data.message,
        });
        toast.success("Catgory Deleted");
        fetchCategories();
      }
    } catch (error) {
      if (error.response.status == "409") {
        let data = error.response.data;
        alert(
          `Attention: i.e Products under this category exist\n\n${data.data
            .map((x, index) => `${index + 1}) ${x.name}`)
            .join(", ")}`
        );
        toast.error(
          `Products with this category exist: ${data.data
            .map((x, index) => `${index + 1}) ${x.name}`)
            .join(", ")}`
        );
        return;
      }
      setPostState({ ...postState, isError: true });
      toast.error("Unable to Delete Category");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const ReturnAppointmentPayment = async (formData) => {
    setPostState({ ...postState, isLoading: true });
    try {
      const { data } = await axios.post(
        `${baseUrl}/shop/refund-payment`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (data.success) {
        toast.success("Amount Refunded");
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Refund Failed");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const makeReturnPayment = async (id) => {
    setPostState({ ...postState, isLoading: true });
    try {
      const { data } = await axios.put(`${baseUrl}/admin/make-return/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (data.success) {
        toast.success("Amount Refunded");
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Refund Failed");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const UpdateProfile = async (formData) => {
    setPostState({ ...postState, isLoading: true, isError: null });
    try {
      const { data } = await axios.put(
        `${baseUrl}/user/profile-update`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        toast.success("Updated");
        setPostState({
          ...postState,
          response: data.message,
        });
        loadUser();
      }
    } catch (error) {
      console.log(error);
      setPostState({ ...postState, isError: true });
    }
  };
  const UpdatePassword = async (formData) => {
    setPostState({ ...postState, isLoading: true, isError: null });
    try {
      const { data } = await axios.put(
        `${baseUrl}/user/update-password`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        toast.success("Password Updated");
        setPostState({
          ...postState,
          response: data.message,
        });
        loadUser();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error(error.response.data.data.error);
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const AddPhoneNumber = async (formData) => {
    setPostState({ ...postState, isLoading: true, isError: null });
    try {
      const { data } = await axios.post(
        `${baseUrl}/user/add-phone-number`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        toast.success("Phone Number Added");
        setPostState({
          ...postState,
          response: data.message,
        });
        loadUser();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Something went wrong, Try again in a moment");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const UpdatePhoneNumber = async (formData) => {
    setPostState({ ...postState, isLoading: true, isError: null });
    try {
      const { data } = await axios.put(
        `${baseUrl}/user/update-phone-number`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        toast.success("Phone Number Updated");
        setPostState({
          ...postState,
          response: data.message,
        });
        loadUser();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Something went wrong, Try again in a moment");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const AddAvatar = async (formData) => {
    setPostState({ ...postState, isLoading: true, isError: null });
    try {
      const { data } = await axios.post(
        `${baseUrl}/user/add-avatar`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        toast.success("Profile Picture Added");
        setPostState({
          ...postState,
          response: data.message,
        });
        loadUser();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Something went wrong, Try again in a moment");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };
  const UpdateAvatar = async (formData) => {
    setPostState({ ...postState, isLoading: true, isError: null });
    try {
      const { data } = await axios.put(
        `${baseUrl}/user/profile-picture`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        toast.success("Profile Picture Changed");
        setPostState({
          ...postState,
          response: data.message,
        });
        loadUser();
      }
    } catch (error) {
      setPostState({ ...postState, isError: true });
      toast.error("Something went wrong, Try again in a moment");
    } finally {
      setPostState({
        ...postState,
        isLoading: false,
      });
    }
  };

  return (
    <PostContext.Provider
      value={{
        ...postState,
        PostPackage,
        DeletePackage,
        EditPackage,
        PostClass,
        EditClassData,
        DeleteClass,
        PostCategory,
        UpdateCategory,
        DeleteCategory,
        UpdateProfile,
        UpdatePassword,
        ReturnAppointmentPayment,
        makeReturnPayment,
        AddPhoneNumber,
        UpdatePhoneNumber,
        AddAvatar,
        UpdateAvatar,
        DeleteProductImage,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};

export const usePostContext = () => useContext(PostContext);
