import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../data/constant";
import { useAuthContext } from "../contexts/AuthContext";
import { useStateContext } from "../contexts/ContextProvider";
import moment from "moment/moment";
import { toast } from "react-toastify";
import Loader from "./Loader";

const SaleDocuments = () => {
  const { token } = useAuthContext();
  const { currentColor } = useStateContext();
  const [documents, setDocuments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [modalData, setModalData] = useState({
    _id: "",
    discount: "",
    startDate: "",
    endDate: "",
  });

  const fetchDocuments = async () => {
    setLoading(true);
    const { data } = await axios.get(`${baseUrl}/sales-document`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (data.success) {
      setDocuments(data.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const openEditModal = (document) => {
    const startDate = document?.document_info?.startDate
      ? moment(document.document_info.startDate).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");

    const endDate = document?.document_info?.endDate
      ? moment(document.document_info.endDate).format("YYYY-MM-DD")
      : moment().add(1, "year").format("YYYY-MM-DD");

    setModalData({
      _id: document._id,
      discount: document?.document_info?.discount || "",
      startDate,
      endDate,
    });

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalData({
      _id: "",
      discount: "",
      startDate: "",
      endDate: "",
    });
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setModalData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdateDocument = async (documentId) => {
    if (!modalData) return;

    try {
      const { data } = await axios.put(
        `${baseUrl}/sales-document/${documentId}`,
        {
          discount: modalData.discount,
          startDate: modalData.startDate,
          endDate: modalData.endDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        toast.success("Discount Setting Updated");
        closeModal();
        fetchDocuments();
      } else {
        toast.error("Unable To Update Discount");
      }
    } catch (error) {
      console.error("Failed to update document:", error);
    }
  };

  return (
    <section className="text-black-shade body-font">
      <div className="container px-5 py-20 md:py-5 mx-auto">
        <div className="flex justify-between items-center w-full mb-10">
          <h1
            style={{
              color: currentColor,
            }}
            className="text-xl md:text-4xl font-medium title-font tracking-widest uppercase"
          >
            Sales Tax Documents
          </h1>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="overflow-x-auto shadow-lg rounded-lg border border-gray-300">
            <table className="min-w-full table-auto bg-white border-collapse">
              <thead className="bg-gray-100 text-gray-700">
                <tr>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    Name
                  </th>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    Email
                  </th>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    Role
                  </th>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    Date To - From
                  </th>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    Document
                  </th>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    Discount
                  </th>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    Edit
                  </th>
                </tr>
              </thead>
              <tbody>
                {documents?.map((document) => (
                  <tr key={document?.id}>
                    <td className="px-6 py-4 border-b text-center">
                      {document?.user?.name}
                    </td>
                    <td className="px-6 py-4 border-b text-center">
                      {document?.user?.email}
                    </td>
                    <td className="px-6 py-4 border-b text-center uppercase">
                      {document?.user?.userRole}
                    </td>
                    <td className="px-6 py-4 border-b text-center uppercase">
                      {moment(document?.document_info?.startDate).format(
                        "YYYY-MM-DD"
                      )}{" "}
                      -{" "}
                      {moment(document?.document_info?.endDate).format(
                        "YYYY-MM-DD"
                      )}
                    </td>
                    <td className="px-6 py-4 border-b text-center uppercase">
                      <a
                        href={document?.documentUrl}
                        target="_blank"
                        className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-500"
                      >
                        {document?.documentName}
                      </a>
                    </td>
                    <td className="px-6 py-4 border-b text-center uppercase">
                      {document?.document_info?.discount
                        ? `${document?.document_info?.discount} %`
                        : "N/A"}
                    </td>
                    <td className="px-6 py-4 border-b text-center uppercase">
                      <button
                        onClick={() => openEditModal(document)}
                        className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-500"
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg w-full max-w-md">
              <h2 className="text-xl font-bold mb-4">Assign Discount</h2>

              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Discount %
                </label>
                <div className="flex items-center border rounded">
                  <span className="px-2 text-sm">%</span>
                  <input
                    type="number"
                    name="discount"
                    value={modalData.discount}
                    onChange={handleInputChange}
                    className="w-full p-2 border-l-0 rounded-r text-sm"
                    required
                  />
                </div>
                {modalData.discount === "" && (
                  <p className="text-red-500 text-xs mt-1">
                    Discount is required.
                  </p>
                )}
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Start Date
                </label>
                <input
                  type="date"
                  name="startDate"
                  value={modalData.startDate}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  End Date
                </label>
                <input
                  type="date"
                  name="endDate"
                  value={modalData.endDate}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  className="px-4 py-2 bg-gray-500 text-white rounded"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-green-500 text-white rounded"
                  onClick={() => handleUpdateDocument(modalData._id)}
                  disabled={modalData.discount === ""}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default SaleDocuments;
