import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../data/constant";
import { useAuthContext } from "../contexts/AuthContext";
import moment from "moment";
import { useStateContext } from "../contexts/ContextProvider";
import Loader from "./Loader";

const WholeSalersAndContractors = () => {
  const { token } = useAuthContext();
  const { currentColor } = useStateContext();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    const { data } = await axios.get(`${baseUrl}/user/get-user-rolewise`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (data.success) {
      setUsers(data.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const openEditModal = (user) => {
    setSelectedUser({ ...user });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setSelectedUser((prev) => ({
      ...prev,
      isVerified: checked,
    }));
  };

  const handleUpdateUser = async () => {
    if (!selectedUser) return;

    try {
      await axios.put(
        `${baseUrl}/user/admin-update-user/${selectedUser._id}`,
        selectedUser,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      closeModal();
      fetchUsers();
    } catch (error) {
      console.error("Failed to update user:", error);
    }
  };

  return (
    <section className="text-black-shade body-font">
      <div className="container px-5 py-20 md:py-5 mx-auto">
        <div className="flex justify-between items-center w-full mb-10">
          <h1
            style={{
              color: currentColor,
            }}
            className="text-xl md:text-4xl font-medium title-font tracking-widest uppercase"
          >
            Wholesalers and Contractors
          </h1>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="overflow-x-auto shadow-lg rounded-lg border border-gray-300">
            <table className="min-w-full table-auto bg-white border-collapse">
              <thead className="bg-gray-100 text-gray-700">
                <tr>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    ID
                  </th>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    Name
                  </th>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    Email
                  </th>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    Role
                  </th>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    Date Created
                  </th>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    Verified
                  </th>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    Discount
                  </th>
                  <th className="px-6 py-4 border-b text-center uppercase">
                    Edit
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.map((user) => (
                  <tr key={user.uuid}>
                    <td className="px-6 py-4 border-b text-center">
                      {user.uuid}
                    </td>
                    <td className="px-6 py-4 border-b text-center">
                      {user.name}
                    </td>
                    <td className="px-6 py-4 border-b text-center">
                      {user.email}
                    </td>
                    <td className="px-6 py-4 border-b text-center uppercase">
                      {user.userRole}
                    </td>
                    <td className="px-6 py-4 border-b text-center">
                      {moment(user.createdAt).format("MM-DD-YYYY")}
                    </td>
                    <td className="px-6 py-4 border-b text-center">
                      {user.isVerified ? "Yes" : "No"}
                    </td>
                    <td className="px-6 py-4 border-b text-center">
                      {user?.discount} %
                    </td>
                    <td className="px-6 py-4 border-b text-center">
                      <button
                        style={{ backgroundColor: currentColor }}
                        className="text-white px-4 py-2 rounded"
                        onClick={() => openEditModal(user)}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Edit Modal */}
        {isModalOpen && selectedUser && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg w-full max-w-md">
              <h2 className="text-xl font-bold mb-4">Edit User</h2>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Name</label>
                <input
                  type="text"
                  name="name"
                  value={selectedUser.name}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Email</label>
                <input
                  type="email"
                  name="email"
                  value={selectedUser.email}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Role</label>
                <select
                  name="userRole"
                  value={selectedUser.userRole}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                >
                  <option value="superadmin">Super Admin</option>
                  <option value="wholesaler">Wholesaler</option>
                  <option value="contractor">Contractor</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Verification Status
                </label>
                <div className="flex items-center space-x-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="isVerified"
                      value="true"
                      checked={selectedUser.isVerified === true}
                      onChange={() =>
                        setSelectedUser((prev) => ({
                          ...prev,
                          isVerified: true,
                        }))
                      }
                      className="w-5 h-5 mr-2"
                    />
                    Verified
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="isVerified"
                      value="false"
                      checked={selectedUser.isVerified === false}
                      onChange={() =>
                        setSelectedUser((prev) => ({
                          ...prev,
                          isVerified: false,
                        }))
                      }
                      className="w-5 h-5 mr-2"
                    />
                    Unverified
                  </label>
                </div>
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  className="px-4 py-2 bg-gray-500 text-white rounded"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-green-500 text-white rounded"
                  onClick={handleUpdateUser}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default WholeSalersAndContractors;
