import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import moment from "moment";
import AreaDetails from "./AreaDetails";

const ProposalDetails = ({ proposal }) => {
  if (!proposal) {
    return (
      <Box sx={{ p: 3, display: "flex", justifyContent: "center" }}>
        <Typography variant="h6" color="textSecondary">
          Select a proposal to view its details.
        </Typography>
      </Box>
    );
  }

  const {
    name,
    revision,
    list_price,
    your_price,
    labour_cost,
    markup,
    createdAt,
    files,
    proposal_uuid,
  } = proposal;

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 3, textAlign: "center" }}>
        <Typography
          variant="h6"
          fontWeight="bold"
          color="primary"
          sx={{
            mb: 1,
            textTransform: "uppercase",
            letterSpacing: 2,
            backgroundColor: "#1976D2",
            padding: "8px 10px",
            borderRadius: 3,
            color: "#fff",
          }}
        >
          {name}
        </Typography>
        <Typography
          variant="h6"
          color="textSecondary"
          sx={{
            mb: 2,
            fontWeight: "600",
            fontStyle: "italic",
            color: "#1976D2",
            fontSize: "0.975rem",
          }}
        >
          Proposal ID: {proposal_uuid}
        </Typography>
        <Divider sx={{ my: 2 }} />
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography
          className="uppercase"
          variant="h6"
          sx={{ fontWeight: "bold", mb: 2 }}
        >
          General Information :
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                border: "1px solid #e0e0e0",
                borderRadius: 2,
                boxShadow: 3,
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: 6,
                },
                p: 2,
              }}
            >
              <CardContent>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  <strong>Revision:</strong> {revision}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  <strong>Last Modified:</strong>{" "}
                  {moment(createdAt).format("MM-DD-YYYY")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                border: "1px solid #e0e0e0",
                borderRadius: 2,
                boxShadow: 3,
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: 6,
                },
                p: 2,
              }}
            >
              <CardContent>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  <strong>List Price:</strong> ${list_price}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  <strong>Your Price:</strong> ${your_price}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Pricing and Cost Section */}
      <Box sx={{ mb: 4 }}>
        <Typography
          className="uppercase"
          variant="h6"
          sx={{ fontWeight: "bold", mb: 2 }}
        >
          Pricing & Costs :
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                border: "1px solid #e0e0e0",
                borderRadius: 2,
                boxShadow: 3,
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: 6,
                },
                p: 2,
              }}
            >
              <CardContent>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  <strong>Labour Cost:</strong> ${labour_cost}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  <strong>Markup:</strong> {markup}%
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {files && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
            Files & Media
          </Typography>
          <Grid container spacing={2}>
            {files.picture && (
              <Grid item xs={6} sm={4} md={3}>
                <Card
                  sx={{
                    boxShadow: 3,
                    transition: "transform 0.2s",
                    "&:hover": { transform: "scale(1.05)" },
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    borderRadius: 2,
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: 150,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundImage: `url(${files.picture.url})`,
                      borderRadius: 1,
                      mb: 2,
                    }}
                  />
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    Picture
                  </Typography>
                </Card>
              </Grid>
            )}
          </Grid>
        </Box>
      )}

      <AreaDetails areaDetails={proposal.areas} />
    </Box>
  );
};

export default ProposalDetails;
