import React, { useState, useEffect } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { usePostContext } from "../contexts/PostContext";
import { useFetchContext } from "../contexts/FetchContext";
import { FaTrash } from "react-icons/fa";
import { Button, CircularProgress } from "@mui/material";

const EditClass = () => {
  const { currentColor } = useStateContext();
  const [btnLoading, setBtnLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const { EditClassData, DeleteProductImage } = usePostContext();
  const { categories, fetchSingleClass } = useFetchContext();

  useEffect(() => {
    const fetchClassData = async () => {
      const { data, success } = await fetchSingleClass(id);
      if (success) {
        formik.setFieldValue("name", data.name);
        formik.setFieldValue("category_id", data.category_id);
        formik.setFieldValue("length", data.length);
        formik.setFieldValue("details", data.details);
        formik.setFieldValue("rating", data.rating);
        formik.setFieldValue("quantity", data.quantity);
        formik.setFieldValue("price", data.price);
        setFiles(data.images || []);
      }
    };

    fetchClassData();
  }, [id]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    formik.setFieldValue("files", selectedFiles);
  };

  const handleRemoveFile = async (public_id) => {
    await DeleteProductImage(id, public_id);
    setFiles(files.filter((file) => file.public_id !== public_id));
  };

  const handleSubmit = async (values) => {
    setBtnLoading(true);
    const formData = new FormData();
    for (let key in values) {
      if (key === "files") {
        values[key].forEach((file) => formData.append("files", file));
      } else {
        formData.append(key, values[key]);
      }
    }
    await EditClassData(id, formData);
    setBtnLoading(false);
    navigate("/products");
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Class name is required"),
    category_id: Yup.string().required("Category is required"),
    details: Yup.string().required("Details are required"),
    rating: Yup.string().required("Rating is required"),
    quantity: Yup.number()
      .required("Quantity is required")
      .positive("Quantity must be positive"),
    price: Yup.number()
      .required("Price is required")
      .positive("Price must be positive"),
    files: Yup.array(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      category_id: "",
      length: "",
      details: "",
      rating: "",
      quantity: "",
      price: "",
      files: [],
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className="bg-gray-50 dark:bg-gray-800 min-h-screen p-8">
      <div className="max-w-4xl mx-auto bg-white dark:bg-gray-900 shadow-lg rounded-lg p-6">
        <h1
          className="text-3xl md:text-4xl font-bold text-gray-800 dark:text-gray-100 mb-6 text-center"
          style={{ color: currentColor }}
        >
          Edit Product
        </h1>
        <form onSubmit={formik.handleSubmit} className="space-y-6">
          {/* Name */}
          <div>
            <label
              htmlFor="name"
              className="block text-gray-600 dark:text-gray-300 mb-1"
            >
              Product Name
            </label>
            <input
              id="name"
              name="name"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full rounded-lg border border-gray-300 dark:border-gray-700 bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-gray-100 focus:ring focus:ring-indigo-300 p-3"
              placeholder="Enter product name"
            />
            {formik.touched.name && formik.errors.name && (
              <p className="text-red-500 text-sm mt-1">{formik.errors.name}</p>
            )}
          </div>

          {/* Category */}
          <div>
            <label
              htmlFor="category_id"
              className="block text-gray-600 dark:text-gray-300 mb-1"
            >
              Category
            </label>
            <select
              id="category_id"
              name="category_id"
              value={formik.values.category_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full rounded-lg border border-gray-300 dark:border-gray-700 bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-gray-100 focus:ring focus:ring-indigo-300 p-3"
            >
              <option value="">Select a category</option>
              {categories?.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </select>
            {formik.touched.category_id && formik.errors.category_id && (
              <p className="text-red-500 text-sm mt-1">
                {formik.errors.category_id}
              </p>
            )}
          </div>

          {/* Other Fields */}
          {["length", "details", "rating", "quantity", "price"].map((field) => (
            <div key={field}>
              <label
                htmlFor={field}
                className="block text-gray-600 dark:text-gray-300 mb-1"
              >
                {field.charAt(0).toUpperCase() + field.slice(1)}
              </label>
              <input
                id={field}
                name={field}
                type={
                  field === "quantity" || field === "price" ? "number" : "text"
                }
                value={formik.values[field]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full rounded-lg border border-gray-300 dark:border-gray-700 bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-gray-100 focus:ring focus:ring-indigo-300 p-3"
                placeholder={`Enter ${field}`}
              />
              {formik.touched[field] && formik.errors[field] && (
                <p className="text-red-500 text-sm mt-1">
                  {formik.errors[field]}
                </p>
              )}
            </div>
          ))}

          {/* File Upload */}
          <div>
            <label
              htmlFor="files"
              className="block text-gray-600 dark:text-gray-300 mb-1"
            >
              Upload Files
            </label>
            <input
              id="files"
              name="files"
              type="file"
              multiple
              onChange={handleFileChange}
              className="block w-full rounded-lg border border-gray-300 dark:border-gray-700 bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-gray-100 focus:ring focus:ring-indigo-300 p-3"
            />
          </div>

          {/* File Previews */}
          <div className="mt-4 grid grid-cols-2 md:grid-cols-3 gap-4">
            {files.map((file) => (
              <div key={file.public_id} className="relative">
                <img
                  src={file.url}
                  alt={file.name}
                  className="rounded-lg w-full h-40 object-cover"
                />
                <button
                  onClick={() => handleRemoveFile(file.public_id)}
                  className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full"
                >
                  <FaTrash />
                </button>
              </div>
            ))}
          </div>

          {/* Submit Button */}
          <div className="text-right">
            <Button
              type="submit"
              variant="contained"
              disabled={btnLoading}
              sx={{ bgcolor: currentColor, color: "#fff" }}
            >
              {btnLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Update"
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditClass;
