import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "./AuthContext";
import { baseUrl } from "../data/constant";

const INITIAL_STATES = {
  users: null,
  categories: null,
  packages: null,
  classes: null,
  projects: null,
  bookings: null,
  totalEarnings: null,
  weeklyEarnings: null,
  recentTransactions: null,
  weeklyStats: null,
  isLoading: null,
  response: null,
  errorResponse: null,
  isError: null,
};

export const FetchContext = createContext(INITIAL_STATES);

export const FetchContextProvider = ({ children }) => {
  const [dataState, setDataState] = useState(INITIAL_STATES);
  const { user, token } = useAuthContext();

  const fetchCategories = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/product-category`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, categories: data.data }));
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
    }
  };
  const fetchClasses = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/product`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, classes: data.data }));
      }
    } catch (error) {
      console.error("Error fetching Product data:", error);
    }
  };
  const fetchProjects = async (search = "") => {
    try {
      const { data } = await axios.get(`${baseUrl}/project/get-all-projects`, {
        params: {
          search,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, projects: data.data }));
      }
    } catch (error) {
      console.error("Error fetching projects data:", error);
    }
  };
  const fetchSingleClass = async (id) => {
    setDataState((prevData) => ({ ...prevData, isLoading: true }));
    try {
      const { data } = await axios.get(`${baseUrl}/product/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("🚀 ~ fetchSingleClass ~ data:", data);
      if (data.success) {
        setDataState((prevData) => ({ ...prevData, isLoading: false }));
        return data;
      }
    } catch (error) {
      setDataState({ ...dataState, isError: true, isLoading: false });
      console.error("Error fetching Product data:", error);
    }
  };
  useEffect(() => {
    user && fetchCategories();
    user && fetchClasses();
    user && fetchProjects();
  }, [user]);

  return (
    <FetchContext.Provider
      value={{
        ...dataState,
        fetchCategories,
        fetchClasses,
        fetchProjects,
        fetchSingleClass,
      }}
    >
      {children}
    </FetchContext.Provider>
  );
};

export const useFetchContext = () => useContext(FetchContext);
