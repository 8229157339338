import axios from "axios";
import { createContext, useState, useContext, useEffect } from "react";
import { useAuthContext } from "./AuthContext";
import { baseUrl } from "../data/constant";

const INITIAL_STATES = {
  products: null,
  rooms: null,
  customers: null,
  isLoading: null,
  response: null,
  errorResponse: null,
  isError: null,
  products: null,
  appointments: null,
  coupons: null,
  revenue: null,
  yearlyRevenue: null,
  monthlyRevenue: null,
  refundedAmounts: {
    total: null,
    percentage: null,
  },
  weeklyStatsSale: null,
  recentTransactions: null,
  disableSlots: null,
};
export const AdminContext = createContext(INITIAL_STATES);

export const AdminContextProvider = ({ children }) => {
  const [dataState, setDataState] = useState(INITIAL_STATES);
  const { user, token, isAdmin } = useAuthContext();

  const fetchAllProducts = async () => {};
  const fetchAllAppoinments = async () => {};
  const fetchAllUsers = async () => {};
  const fetchCoupons = async () => {};
  const fetchSingleCoupon = async (id) => {};
  const fetchSingleUser = async (id) => {};
  const fetchSingleAppointment = async (id) => {};
  const fetchRevenue = async (id) => {};
  const fetchYearlyRevenue = async () => {};

  const refundedAmounts = async () => {};

  const fetchRevenueByMonth = async () => {};

  const fetchWeeklyStats = async () => {};

  const fetchRecentTransactions = async () => {};

  useEffect(() => {
    if (isAdmin) {
      fetchAllProducts();
      fetchAllAppoinments();
      fetchAllUsers();
      fetchRevenue();
      fetchYearlyRevenue();
      refundedAmounts();
      fetchRevenueByMonth();
      fetchWeeklyStats();
      fetchCoupons();
      fetchRecentTransactions();
    }
  }, [isAdmin]);

  return (
    <AdminContext.Provider
      value={{
        fetchAllAppoinments,
        fetchAllUsers,
        fetchSingleUser,
        fetchSingleAppointment,
        fetchRevenue,
        fetchCoupons,
        fetchYearlyRevenue,
        fetchSingleCoupon,
        fetchAllProducts,
        ...dataState,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdminContext = () => useContext(AdminContext);
