import React, { useEffect, useState } from "react";
import { FiBarChart, FiShoppingCart } from "react-icons/fi";
import { BsBoxSeam, BsCurrencyDollar } from "react-icons/bs";
import { MdOutlineSupervisorAccount } from "react-icons/md";
import { useStateContext } from "../contexts/ContextProvider";
import { useAuthContext } from "../contexts/AuthContext";
import { baseUrl } from "../data/constant";
import axios from "axios";

const Ecommerce = () => {
  const { token } = useAuthContext();
  const { currentColor } = useStateContext();
  const [stats, setStats] = useState({
    total_sales: 0,
    wholesalers: 0,
    contractors: 0,
    products: 0,
    top_three_products: [],
    order_statuses: {
      processing: 0,
      cancelled: 0,
      delivered: 0,
      // in_process: 0,
    },
    projects: 0,
    categories: 0,
    proposals_generated: 0,
  });
  const fetchStats = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/stats`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.data) {
        setStats(data.data);
      }
    } catch (error) {
      console.error("Error fetching proposals data:", error);
    }
  };
  useEffect(() => {
    fetchStats();
  }, []);

  const totalSales = stats.total_sales;
  const totalWholesalers = stats.wholesalers;
  const totalContractors = stats.contractors;
  const totalProducts = stats.products;
  const topSellingProducts = stats.top_three_products;
  const orderStatuses = stats.order_statuses;
  const totalProjects = stats.projects;
  const totalCategories = stats.categories;
  const proposalsGeneratedLastWeek = stats.proposals_generated;

  // Static earning data
  const earningData = [
    {
      icon: <MdOutlineSupervisorAccount />,
      amount: totalWholesalers,
      title: "Wholesalers",
      iconColor: "#03C9D7",
      iconBg: "#E5FAFB",
      pcColor: "red-600",
    },
    {
      icon: <MdOutlineSupervisorAccount />,
      amount: totalContractors,
      title: "Contractors",
      iconColor: "#03C9D7",
      iconBg: "#E5FAFB",
      pcColor: "red-600",
    },
    {
      icon: <BsBoxSeam />,
      amount: totalProducts,
      title: "Products",
      iconColor: "rgb(255, 244, 229)",
      iconBg: "rgb(254, 201, 15)",
      pcColor: "green-600",
    },
    {
      icon: <FiBarChart />,
      amount: `$${totalSales.toFixed(2)}`,
      title: "Total Sales",
      iconColor: "rgb(228, 106, 118)",
      iconBg: "rgb(255, 244, 229)",
      pcColor: "green-600",
    },
  ];

  return (
    <div className="mt-6 mx-auto container">
      {/* First Row: Total Sales and Stat Cards */}
      <div className="flex flex-wrap m-3 lg:flex-nowrap justify-center">
        <div className="bg-white dark:text-gray-200 lg:mr-3 dark:bg-secondary-dark-bg h-44 rounded-xl w-full xl:w-96 lg:w-60 p-8 pt-9 bg-hero-pattern bg-no-repeat bg-cover bg-center">
          <div className="flex justify-between items-center">
            <div>
              <p style={{ color: currentColor }} className="font-bold">
                Total Sales
              </p>
              <p className="text-2xl">${totalSales?.toFixed(2)}</p>
            </div>
            <button
              type="button"
              style={{ backgroundColor: currentColor }}
              className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full p-4"
            >
              <BsCurrencyDollar />
            </button>
          </div>
        </div>

        {/* Stat Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mt-3 lg:mt-0 lg:mr-3 w-full justify-center gap-1 items-center">
          {earningData.map((item) => (
            <div
              key={item.title}
              className="bg-white flex items-center md:flex-col gap-4 md:gap-0 h-44 w-full dark:text-gray-200 dark:bg-secondary-dark-bg p-4 pt-9 rounded-2xl "
            >
              <button
                type="button"
                style={{ color: item.iconColor, backgroundColor: item.iconBg }}
                className="text-2xl opacity-0.9 rounded-full p-4 hover:drop-shadow-xl"
              >
                {item.icon}
              </button>
              <p className="md:mt-3">
                <span className="text-lg font-semibold">{item.amount}</span>
              </p>
              <p className="text-sm text-gray-400 md:mt-1">{item.title}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Top 3 Most Selling Products */}
      <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg w-full p-6 rounded-2xl mt-8">
        <p className="font-semibold text-xl mb-6">
          Top 3 Most Selling Products
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {topSellingProducts.map((product, index) => (
            <div
              key={index}
              className="bg-light p-6 rounded-xl shadow-xl flex flex-col justify-between items-center text-center"
              style={{ backgroundColor: currentColor }}
            >
              <p className="text-xl font-semibold text-white">{product.name}</p>
              <p className="text-lg text-white">
                ${product.total_sold.toFixed(2)}
              </p>
              <p className="text-sm text-white mt-2">
                Sales Count: {product.count}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Order Statuses */}
      <div className="bg-white dark:bg-secondary-dark-bg dark:text-gray-200 w-full p-6 rounded-2xl mt-8">
        <p className="font-semibold text-xl mb-6">Order Statuses</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {Object.keys(orderStatuses).map((status) => (
            <div
              key={status}
              className={`p-6 rounded-xl shadow-xl flex flex-col items-center justify-center text-center
        ${status === "processing" ? "bg-yellow-200 dark:bg-yellow-800" : ""}
        ${status === "cancelled" ? "bg-red-200 dark:bg-red-800" : ""}
        ${status === "delivered" ? "bg-green-200 dark:bg-green-800" : ""}`}
            >
              {/* Status Icon */}
              <div
                className={`text-4xl mb-4
          ${
            status === "processing"
              ? "text-yellow-600 dark:text-yellow-300"
              : ""
          }
          ${status === "cancelled" ? "text-red-600 dark:text-red-300" : ""}
          ${status === "delivered" ? "text-green-600 dark:text-green-300" : ""}
        `}
              >
                {status === "processing" && <FiShoppingCart />}
                {status === "cancelled" && <BsBoxSeam />}
                {status === "delivered" && <BsCurrencyDollar />}
              </div>
              {/* Status Text with Color and Increased Font Size */}
              <p
                className={`text-2xl font-bold
          ${
            status === "processing"
              ? "text-yellow-600 dark:text-yellow-300"
              : ""
          }
          ${status === "cancelled" ? "text-red-600 dark:text-red-300" : ""}
          ${status === "delivered" ? "text-green-600 dark:text-green-300" : ""}
        `}
              >
                {orderStatuses[status]}
              </p>
              <p
                className={`text-lg font-semibold mt-2
          ${
            status === "processing"
              ? "text-yellow-600 dark:text-yellow-300"
              : ""
          }
          ${status === "cancelled" ? "text-red-600 dark:text-red-300" : ""}
          ${status === "delivered" ? "text-green-600 dark:text-green-300" : ""}
        `}
              >
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Projects & Categories Overview */}
      <div className="mt-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {/* Project Card */}
          <div className="bg-gradient-to-r from-blue-500 to-teal-400 dark:bg-gradient-to-r dark:from-blue-700 dark:to-teal-600 text-white p-6 rounded-2xl shadow-lg flex flex-col items-center justify-center text-center">
            <p className="text-xl font-semibold">Projects</p>
            <p className="text-3xl font-semibold">{totalProjects}</p>
          </div>

          {/* Categories Card */}
          <div className="bg-gradient-to-r from-orange-400 to-yellow-500 dark:bg-gradient-to-r dark:from-orange-600 dark:to-yellow-600 text-white p-6 rounded-2xl shadow-lg flex flex-col items-center justify-center text-center">
            <p className="text-xl font-semibold">Categories</p>
            <p className="text-3xl font-semibold">{totalCategories}</p>
          </div>

          {/* Contractors Card */}
          <div className="bg-gradient-to-r from-purple-500 to-pink-500 dark:bg-gradient-to-r dark:from-purple-700 dark:to-pink-700 text-white p-6 rounded-2xl shadow-lg flex flex-col items-center justify-center text-center">
            <p className="text-xl font-semibold">Contractors</p>
            <p className="text-3xl font-semibold">{totalContractors}</p>
          </div>

          {/* Proposals Generated Card */}
          <div className="bg-gradient-to-r from-green-500 to-teal-400 dark:bg-gradient-to-r dark:from-green-700 dark:to-teal-600 text-white p-6 rounded-2xl shadow-lg flex flex-col items-center justify-center text-center">
            <p className="text-xl font-semibold">Proposals Generated</p>
            <p className="text-3xl font-semibold">
              {proposalsGeneratedLastWeek}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ecommerce;
