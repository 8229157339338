import React, { useEffect, useRef, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { MdEdit, MdDelete } from "react-icons/md";
import { usePostContext } from "../contexts/PostContext";
import CustomModal from "./CustomModal";
import { Link } from "react-router-dom";
import moment from "moment";
import { SiGoogleclassroom } from "react-icons/si";

const Class = ({ id, packageName, date, images }) => {
  const { currentColor, currentMode } = useStateContext();
  const { DeleteClass } = usePostContext();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [day, setDay] = useState(moment(date).format("DD-MMMM-YYYY"));

  const handleDeleteOpen = () => setDeleteOpen(true);

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const deleteCoupon = async () => {
    handleDeleteClose();
    await DeleteClass(id);
  };

  return (
    <>
      <CustomModal open={deleteOpen} handleClose={handleDeleteClose}>
        <div className="w-full flex flex-col">
          <div className="py-4 font-semibold">
            Are you sure want to delete this Product ?
          </div>
          <div className="ml-auto font-semibold">
            <button
              onClick={handleDeleteClose}
              style={{ backgroundColor: currentColor }}
              className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mr-2"
            >
              No
            </button>
            <button
              onClick={deleteCoupon}
              style={{ backgroundColor: currentColor }}
              className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
            >
              Yes
            </button>
          </div>
        </div>
      </CustomModal>
      <div className="p-2 flex flex-col cursor-default md:w-1/2 w-full">
        <div className="h-full backdrop-blur-3xl bg-black dark:bg-white bg-opacity-5 dark:bg-opacity-5 transition duration-300 flex flex-col w-full items-center justify-center border-black-shade dark:border-white-shade border p-4 rounded-lg">
          <div className="flex items-center text-black dark:text-white w-full">
            <div className="flex my-2 space-y-3 flex-col w-full">
              <div className="flex items-center flex-col justify-center gap-x-4">
                <div className="image-container flex gap-2 justify-center mb-4">
                  {images && images.length > 0 ? (
                    images.map((img, index) => (
                      <img
                        key={index}
                        src={img.url}
                        alt={`Class ${packageName} Image ${index + 1}`}
                        className="class-image h-20 w-20 object-cover rounded-md"
                      />
                    ))
                  ) : (
                    <p>No images available</p>
                  )}
                </div>
                <div className="flex items-center justify-between flex-grow">
                  <span className="title-font text-xl font-medium">
                    {packageName}
                  </span>
                </div>
              </div>
              <div className="flex items-start">
                <h2 className="w-1/3 font-semibold text-base">Date:</h2>
                <h2 className="w-2/3 text-base">{day}</h2>
              </div>
            </div>
            {/* <div className="flex items-start flex-grow">
                <h2 className=" w-1/3 title-font text-base line-clamp-2 font-medium">
                  Morning Time :
                </h2>
                <h2 className=" w-2/3 title-font text-base line-clamp-2 font-medium">
                  {morningTime?.map((time, index) => (
                    <span>{` ${time} ${
                      index !== morningTime.length - 1 ? "," : ""
                    }`}</span>
                  ))}
                </h2>
              </div>
              <div className="flex items-start flex-grow">
                <h2 className=" w-1/3 title-font text-base line-clamp-2 font-medium">
                  Afternoon Time :
                </h2>
                <h2 className=" w-2/3 title-font text-base line-clamp-2 font-medium">
                  {afterNoon?.map((time, index) => (
                    <span>{` ${time} ${
                      index !== afterNoon.length - 1 ? "," : ""
                    }`}</span>
                  ))}
                </h2>
              </div>
              <div className="flex items-start flex-grow">
                <h2 className=" w-1/3 title-font text-base line-clamp-2 font-medium">
                  Evening Time :
                </h2>
                <h2 className=" w-2/3 title-font text-base line-clamp-2 font-medium">
                  {evening?.map((time, index) => (
                    <span>{` ${time} ${
                      index !== evening.length - 1 ? "," : ""
                    }`}</span>
                  ))}
                </h2>
              </div>
              <div className="flex items-start flex-grow">
                <h2 className=" w-1/3 title-font text-base line-clamp-2 font-medium">
                  Day :
                </h2>
                <h2 className=" w-2/3 title-font text-base line-clamp-2 font-medium">
                  {day}
                </h2>
              </div>
               */}
            <div className="flex my-2 justify-between w-full">
              <div className="flex w-full items-center justify-center">
                <Link
                  to={`/edit-product/${id}`}
                  style={{ backgroundColor: currentColor }}
                  className="py-2 flex transition duration-300 items-center justify-center rounded-md w-1/2 hover:text-white-shade dark:hover:text-black-shade hover:bg-black-shade dark:hover:bg-white-shade cursor-pointer "
                >
                  Edit
                </Link>
              </div>
              <div className="flex w-full items-center justify-center">
                <button
                  onClick={handleDeleteOpen}
                  style={{ backgroundColor: currentColor }}
                  className="py-2 flex transition duration-300 items-center justify-center rounded-md w-1/2 hover:text-white-shade dark:hover:text-black-shade hover:bg-black-shade dark:hover:bg-white-shade cursor-pointer "
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Class;
