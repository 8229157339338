import {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../data/constant";

const initialAuthState = {
  isAdmin: true,
  isLoggedIn: null,
  user: null,
  token: null,
  error: null,
  loading: false,
  success: false,
};
const AuthContext = createContext(initialAuthState);

export const AuthContextProvider = ({ children }) => {
  const [authState, setAuthState] = useState(initialAuthState);

  const login = async (credentials) => {
    setAuthState((prevData) => ({ ...prevData, loading: true, error: null }));
    try {
      const { data } = await axios.post(
        `${baseUrl}/auth/admin-login`,
        credentials,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (data.success) {
        localStorage.setItem("token", data.token);
        loadUser();
        toast.success("Logged In Successfully");
      }
    } catch (error) {
      setAuthState((prevData) => ({
        ...prevData,
        loading: false,
        error: error.response,
        success: false,
      }));
      if (error?.response?.status >= 400 && error?.response?.status < 500) {
        return toast.warning(error?.response?.data?.data?.error);
      }
      toast.error(error?.response?.data?.data?.error || "Something Went Wrong");
    }
  };

  const logout = async () => {
    try {
      localStorage.removeItem("token");
      setAuthState({
        isLoggedIn: false,
        user: null,
        token: null,
      });
      loadUser();
    } catch {
      setAuthState((prevData) => ({
        ...prevData,
        loading: false,
        error: true,
      }));
    }
  };

  const loadUser = useCallback(async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        setAuthState((prevData) => ({
          ...prevData,
          loading: true,
          token: token,
          error: null,
        }));
        const { data } = await axios.get(`${baseUrl}/user/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (data.success) {
          setAuthState((prevData) => ({
            ...prevData,
            isLoggedIn: true,
            user: data.data,
            success: true,
            loading: false,
          }));
        }
      } catch (error) {
        setAuthState((prevData) => ({
          ...prevData,
          error: null,
          isLoggedIn: false,
          success: false,
          loading: false,
        }));
      }
    } else {
      setAuthState((prevData) => ({
        ...prevData,
        loading: false,
        isLoggedIn: false,
        success: false,
      }));
    }
  }, []);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        loadUser,
        ...authState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
