import {
  Chip,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const AreaDetails = ({ areaDetails }) => {
  return (
    <div className="areas-section mt-5">
      <Typography
        className="uppercase"
        variant="h6"
        sx={{ fontWeight: "bold", mb: 2 }}
      >
        Proposed Areas :
      </Typography>

      {areaDetails?.length > 0 ? (
        areaDetails.map((area) => (
          <Paper
            key={area._id}
            elevation={3}
            sx={{
              marginBottom: 3,
              padding: 2,
              borderRadius: 2,
              transition: "transform 0.2s, box-shadow 0.3s",
              "&:hover": {
                transform: "scale(1.03)",
                boxShadow: 6,
              },
            }}
          >
            <Typography
              variant="h6"
              className="uppercase"
              sx={{ fontWeight: "bold", color: "primary.main" }}
            >
              {area.name}
            </Typography>

            <Divider sx={{ marginTop: 2, marginBottom: 2 }} />

            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table sx={{ minWidth: 650 }} aria-label="product table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="uppercase"
                      align="center"
                      sx={{ fontWeight: "bold", color: "error.dark" }}
                    >
                      Product Name
                    </TableCell>
                    <TableCell
                      className="uppercase"
                      align="center"
                      sx={{ fontWeight: "bold", color: "error.dark" }}
                    >
                      Length
                    </TableCell>
                    <TableCell
                      className="uppercase"
                      align="center"
                      sx={{ fontWeight: "bold", color: "error.dark" }}
                    >
                      Price
                    </TableCell>
                    <TableCell
                      className="uppercase"
                      align="center"
                      sx={{ fontWeight: "bold", color: "error.dark" }}
                    >
                      Details
                    </TableCell>
                    <TableCell
                      className="uppercase"
                      align="center"
                      sx={{ fontWeight: "bold", color: "error.dark" }}
                    >
                      Quantity
                    </TableCell>
                    <TableCell
                      className="uppercase"
                      align="center"
                      sx={{ fontWeight: "bold", color: "error.dark" }}
                    >
                      Rating
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {area?.products?.map((product) => (
                    <TableRow
                      key={product.product}
                      sx={{ "&:hover": { backgroundColor: "#f5f5f5" } }}
                    >
                      <TableCell align="center" component="th" scope="row">
                        {product.name}
                      </TableCell>
                      <TableCell align="center">{product.length}</TableCell>
                      <TableCell align="center">{product.unit_price}</TableCell>
                      <TableCell align="center">{product.details}</TableCell>
                      <TableCell align="center">{product.quantity}</TableCell>
                      <TableCell align="center">{product.rating}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ))
      ) : (
        <Typography variant="h6" sx={{ textAlign: "center", marginTop: 4 }}>
          No areas available for this proposal.
        </Typography>
      )}
    </div>
  );
};

export default AreaDetails;
