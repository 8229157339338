import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import { baseUrl } from "../data/constant";
import axios from "axios";
import { useAuthContext } from "../contexts/AuthContext";
import ProposalDetails from "./ProposalDetail";

const ProposalTabs = ({ projectId }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [proposals, setProposals] = useState([]);
  const { token } = useAuthContext();

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const fetchProposals = async () => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/proposal/project/${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data.success) {
        setProposals(data.data);
      } else {
        setProposals([]);
      }
    } catch (error) {
      console.error("Error fetching proposals data:", error);
      setProposals([]);
    }
  };

  useEffect(() => {
    if (projectId && token) {
      fetchProposals();
    }
  }, [projectId, token]);

  return (
    <Box sx={{ width: "100%", marginTop: 3 }}>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        aria-label="proposal tabs"
        variant="fullWidth"
        TabIndicatorProps={{
          style: {
            backgroundColor: "red",
          },
        }}
      >
        {proposals.map((proposal) => (
          <Tab
            key={proposal._id}
            label={
              <Typography sx={{ fontWeight: 400, color: "#333" }}>
                {proposal.name}
              </Typography>
            }
            sx={{
              "&.Mui-selected": {
                color: "red",
              },
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
            }}
          />
        ))}
      </Tabs>

      {proposals.map((proposal, index) => (
        <Box
          key={proposal._id}
          role="tabpanel"
          hidden={selectedTab !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
        >
          {selectedTab === index && (
            <Box p={3}>
              <Typography variant="h6">{proposal.details}</Typography>
            </Box>
          )}
        </Box>
      ))}
      <ProposalDetails proposal={proposals[selectedTab]} />
    </Box>
  );
};

export default ProposalTabs;
